<template>
  <div class="gigx" v-if="gigx">
    <a class="back-to" @click="$router.back()">
      <v-icon size="50">$arrow_left</v-icon>
    </a>
    <div class="gigx-page__header">
      <div v-if="gigx">
        <span class="page-title">Gig Extension</span>
        <span v-if="gigx.status == 'canceled'" class="gigx__canceled"
          >Canceled</span
        >
      </div>
      <div class="gigx-page__subheader">
        For
        <router-link
          :to="`/hire-requests/${gigx.hire_request_monday_com_item_id}`"
          >Hire Request #{{ gigx.hire_request_monday_com_item_id }}</router-link
        ><br />
        <div
          class="gigx__status"
          :class="`gigx__status--${gigx.agreement_status}`"
        >
          {{
            gigx.signed_offsite
              ? "Signed Offsite"
              : extensionStatusesShort[gigx.agreement_status]
          }}
        </div>
      </div>
    </div>
    <FormRow label="Contractor’s Name:" light class="form-row--no-input">
      {{ gigx.creator.full_name }} (<a
        :href="`mailto:${gigx.creator.email}`"
        target="_blank"
        >{{ gigx.creator.email }}</a
      >)
    </FormRow>
    <FormRow label="Extended Date:" light class="form-row--no-input">
      {{ gigx.extended_date | moment("timezone", currentTimezone, "M/D/YYYY") }}
    </FormRow>
    <FormRow label="Rate Changes?:" light class="form-row--no-input">
      {{ gigx.rate_changes ? "Yes" : "No" }}
    </FormRow>
    <FormRow label="Rate of Pay:" light class="form-row--no-input">
      {{ rateTypeLabels[gigx.rate_type] }}
    </FormRow>
    <FormRow label="Rate Amount:" light class="form-row--no-input">
      {{ (gigx.rate_type == "flat_fee" ? gigx.amount : gigx.rate) | currency }}
    </FormRow>
    <FormRow label="Related Gigs:" light class="form-row--no-input">
      <div
        v-for="item in gigx.work_assignments"
        :key="`work_assignment--${item.id}`"
        class="gigx__work-assignment"
      >
        <div class="gigx__work-assignment__details">
          <div class="gigx__work-assignment__info">
            <div class="gigx__work-assignment__title">{{ item.title }}</div>
            <div
              class="hr__work-assignment__info__detail"
              v-if="item.hiring_manager"
            >
              Hiring Manager: {{ item.hiring_manager.full_name }}
            </div>
            <div
              class="hr__work-assignment__info__detail"
              v-if="item.qb_class_name"
            >
              Team/Department: {{ item.qb_class_name }}
            </div>
          </div>
        </div>
      </div>
    </FormRow>
    <div v-if="gigx.status != 'canceled'">
      <div class="gigx__actions">
        <v-btn
          v-if="currentUser.is_people_team || currentUser.is_finance"
          @click="cancelDialog = true"
          color="primary"
          elevation="0"
          >Cancel Extension</v-btn
        >
      </div>
    </div>
    <div v-else>
      <div class="gigx__actions">
        <v-btn
          v-if="currentUser.is_people_team || currentUser.is_finance"
          @click="unCancelDialog = true"
          color="primary"
          elevation="0"
          >Uncancel</v-btn
        >
      </div>
    </div>
    <AreYouSureDialog
      :dialog="cancelDialog"
      @yes="cancel(true)"
      @no="cancelDialog = false"
    >
      <p>This will make this gig extension unavailable for contract signing.</p>
      <p>Are you sure you want to cancel this gig extension?</p>
    </AreYouSureDialog>
    <AreYouSureDialog
      :dialog="unCancelDialog"
      @yes="cancel(false)"
      @no="unCancelDialog = false"
    >
      <p>This will make this gig extension available for contract signing.</p>
      <p>Are you sure you want to uncancel this gig extension?</p>
    </AreYouSureDialog>
    <PleaseWaitDialog :dialog="pleaseWait" />
  </div>
</template>

<script>
import InvoiceLabelsMixin from "@/mixins/invoices/invoice-labels.mixin";
import GigExtensionLabelsMixin from "@/mixins/gig-extension-labels.mixin";
import AgreementLabelsMixin from "@/mixins/agreements/agreement-labels.mixin";

export default {
  mixins: [InvoiceLabelsMixin, GigExtensionLabelsMixin, AgreementLabelsMixin],
  data() {
    return {
      gigx: null,
      cancelDialog: false,
      unCancelDialog: false,
      pleaseWait: false,
    };
  },
  mounted() {
    this.fetchGigx();
  },
  methods: {
    async fetchGigx() {
      const { data } = await this.$api.GigExtension.get({
        id: this.$route.params.id,
      });
      this.gigx = data;
    },
    async cancel(value) {
      if (value) this.pleaseWait = true;
      try {
        const res = await this.$api.GigExtension.updateCanceled({
          id: this.gigx.id,
          value,
        });
        if (res.status == 200) {
          this.pleaseWait = false;
          this.cancelDialog = false;
          this.unCancelDialog = false;
          this.gigx = res.data;
          this.$alert(
            `Gig extension ${value ? "canceled" : "uncanceled"} successfully`
          );
        }
      } catch (e) {
        this.pleaseWait = false;
        this.cancelDialog = false;
        this.unCancelDialog = false;
        this.$alert("Something went wrong", "error");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.gigx {
  &-page {
    &__header {
      margin-top: 100px;
      margin-bottom: 1em;
    }
  }

  &__status {
    display: block;
    text-decoration: none;
    font-weight: bold;
    &--OUT_FOR_SIGNATURE {
      color: #e8852b;
    }
    &--SIGNED,
    &--SIGNED_OFFSITE {
      color: #61b90c;
    }
  }

  ::v-deep {
    .form-label {
      margin-top: 0;
      min-width: 150px;
    }
  }

  &__work-assignment {
    display: flex;
    padding: 0 0 15px;
    margin: 0 0 15px;
    &:not(:last-child) {
      border-bottom: 2px solid #dcdce1;
    }
    &-list {
      margin-left: 4em;
    }
    &__details {
      border: 2px solid #262729;
      border-radius: 11px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 15px 17px;
    }

    &__title {
      font-weight: bold;
    }
  }

  &__actions {
    display: flex;
    justify-content: right;
    > div {
      margin-left: 1em;
    }
  }

  &__canceled {
    display: inline-block;
    background: #e12d1b;
    color: white;
    font-weight: bold;
    padding: 0.2em 0.5em;
    margin-left: 1.5em;
    transform: translateY(-0.5em);
  }

  .form-row--no-input::v-deep .form-inputs {
    padding: 0;
  }
}
</style>
