<template>
  <div class="hr">
    <v-form ref="form" v-if="form">
      <div class="hr__main">
        <v-row>
          <v-col :cols="['xs', 'sm'].includes($size) ? 12 : 4">
            <FormRow label="Creator" light>
              <div class="hr__avatar">
                <TableRowAvatar
                  :user="form.creator"
                  :path="`/p/${
                    form.creator.username || form.creator.id
                  }/hire-requests`"
                />
              </div>
            </FormRow>
          </v-col>
          <v-col :cols="['xs', 'sm'].includes($size) ? 12 : 8">
            <FormRow label="Submitted by" light>
              <div class="hr__avatar">
                <TableRowAvatar :user="form.submitter" newTab />
              </div>
            </FormRow>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="['xs', 'sm'].includes($size) ? 12 : 4">
            <FormRow label="Start Date" light>
              <div class="hr__txt">
                {{
                  form.start_date
                    | moment("timezone", currentTimezone, "M/D/YYYY")
                }}
              </div>
            </FormRow>
          </v-col>
          <v-col :cols="['xs', 'sm'].includes($size) ? 12 : 8">
            <FormRow label="End Date" light>
              <div class="hr__txt">
                {{
                  form.end_date
                    | moment("timezone", currentTimezone, "M/D/YYYY")
                }}
              </div>
            </FormRow>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="['xs', 'sm'].includes($size) ? 12 : 4">
            <FormRow label="Rate Type" light>
              <div class="hr__txt">
                {{ rateTypeLabels[form.rate_type] }}
              </div>
            </FormRow>
          </v-col>
          <v-col :cols="['xs', 'sm'].includes($size) ? 12 : 8">
            <v-row>
              <v-col :cols="['xs', 'sm'].includes($size) ? 12 : 6">
                <FormRow
                  :label="`${rateTypeLabels[form.rate_type]} Rate`"
                  light
                  v-if="form.rate_type != 'flat_fee'"
                >
                  <div class="hr__txt">
                    {{ form.rate | currency }}
                  </div>
                </FormRow>
                <FormRow label="Amount" light v-else>
                  <div class="hr__txt">
                    {{ form.amount | currency }}
                  </div>
                </FormRow>
              </v-col>
              <v-col :cols="['xs', 'sm'].includes($size) ? 12 : 6">
                <FormRow
                  label="Expected Hrs."
                  light
                  v-if="form.rate_type != 'flat_fee'"
                >
                  <div class="hr__txt">
                    {{ form.estimated_hours }}
                  </div>
                </FormRow>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <FormRow
          label="Description of Services"
          light
          class="form-row__long-label"
        >
          <div class="hr__txt hr__txt--breaks">
            {{ form.job_description }}
          </div>
        </FormRow>
        <FormRow label="Additional Rate Details" light>
          <div class="hr__txt hr__txt--breaks">
            {{ form.rate_details }}
          </div>
        </FormRow>
      </div>
      <div class="hr__work-assignments">
        <div class="hr__gigs-header">
          <div class="form-label">Gigs</div>
          <div
            v-if="form.contract_signed_offsite"
            class="hr__contract-signed-offsite"
          >
            Contract Signed Offsite
          </div>
        </div>
        <div class="hr__work-assignment-list">
          <div
            v-for="(wa, idx) in form.work_assignments"
            :key="`work-assignment_${wa.id || 'new'}_${idx}`"
            class="hr__work-assignment"
          >
            <div class="hr__work-assignment__details">
              <div class="hr__work-assignment__info">
                <div class="hr__work-assignment__title">{{ wa.title }}</div>
                <div
                  class="hr__work-assignment__info__detail"
                  v-if="wa.hiring_manager"
                >
                  Hiring Manager:
                  <UpdatableHm
                    :gig="wa"
                    @refresh="$emit('refresh')"
                    v-if="
                      currentUser.roles.find((t) =>
                        ['finance', 'people_team'].includes(t.name)
                      )
                    "
                  />
                  <span v-else>{{ wa.hiring_manager.full_name }}</span>
                </div>
                <div
                  class="hr__work-assignment__info__detail"
                  v-if="wa.qb_class_name"
                >
                  Team/Department:
                  <UpdatableTeam
                    :gig="wa"
                    @refresh="$emit('refresh')"
                    v-if="
                      currentUser.roles.find((t) =>
                        ['finance', 'people_team'].includes(t.name)
                      )
                    "
                  />
                  <span v-else>{{ wa.qb_class_name }}</span>
                </div>
              </div>
              <div class="hr__work-assignment__contracts">
                <div
                  class="hr__work-assignment__status"
                  :class="`hr__work-assignment__status--${wa.agreement_status}`"
                >
                  {{ agreementStatuses[wa.agreement_status] }}
                </div>
                <router-link
                  class="hr__work-assignment__extension"
                  :class="[
                    `hr__work-assignment__extension--agreement--${extension.agreement_status}`,
                    `hr__work-assignment__extension--${extension.status}`,
                  ]"
                  v-for="extension in wa.gig_extensions"
                  :key="extension.id"
                  :to="`/gig-extension/${extension.id}`"
                >
                  {{
                    extension.extended_date
                      | moment("timezone", currentTimezone, "M/D/YYYY")
                  }}
                  {{
                    extension.status == "canceled"
                      ? "Gig Extension Canceled"
                      : extensionStatuses[extension.agreement_status]
                  }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="form.status != 'canceled'">
        <div class="hr__actions">
          <v-btn
            v-if="currentUser.is_people_team && agreementNeededForAll"
            :to="`/monday-onboarding/${this.form.monday_com_item_id}/edit`"
            color="#262729"
            elevation="0"
            >Edit</v-btn
          >
          <v-btn
            v-if="currentUser.is_people_team || currentUser.is_finance"
            @click="cancelDialog = true"
            color="primary"
            elevation="0"
            >Cancel</v-btn
          >
          <v-btn
            v-if="currentUser.is_people_team && form.agreement_status == 'NONE'"
            @click="signedOffsiteDialog = true"
            color="#56565A"
            elevation="0"
            >Contract Signed Offsite</v-btn
          >
          <v-btn
            v-if="currentUser.is_people_team && form.agreement_status == 'NONE'"
            @click="sendDialog = true"
            color="primary"
            elevation="0"
            >Send Gig Contract</v-btn
          >
          <v-btn
            v-if="
              currentUser.is_people_team && form.agreement_status == 'SIGNED'
            "
            @click="extensionDialog = true"
            color="primary"
            elevation="0"
            >Send Gig Extension</v-btn
          >
        </div>
      </div>
      <div v-else>
        <div class="hr__actions">
          <v-btn
            v-if="currentUser.is_people_team || currentUser.is_finance"
            @click="unCancelDialog = true"
            color="primary"
            elevation="0"
            >Uncancel</v-btn
          >
        </div>
      </div>
    </v-form>
    <SendGigContractDialog
      :dialog="sendDialog"
      @close="sendDialog = false"
      :hr="form"
      @refresh="$emit('refresh')"
    />
    <ContractSignedOffsiteDialog
      :dialog="signedOffsiteDialog"
      @close="signedOffsiteDialog = false"
      :hr="form"
      @refresh="$emit('refresh')"
    />
    <SendGigExtensionDialog
      v-if="form"
      :dialog="extensionDialog"
      @close="extensionDialog = false"
      :hr="form"
      :suggestion="mondayComExtensionSuggestion"
      @refresh="$emit('refresh')"
    />
    <AreYouSureDialog
      :dialog="cancelDialog"
      @yes="cancel(true)"
      @no="cancelDialog = false"
    >
      <p>
        This will make gigs from this Hire Request unavailable for invoicing or
        contract signing.
      </p>
      <p>Are you sure you want to cancel this Hire Request?</p>
    </AreYouSureDialog>
    <AreYouSureDialog
      :dialog="unCancelDialog"
      @yes="cancel(false)"
      @no="unCancelDialog = false"
    >
      <p>
        This will make gigs from this hire request available for invoicing and
        contract signing.
      </p>
      <p>Are you sure you want to uncancel this Hire Request?</p>
    </AreYouSureDialog>
  </div>
</template>

<script>
import InvoiceLabelsMixin from "@/mixins/invoices/invoice-labels.mixin";
import AgreementLabelsMixin from "@/mixins/agreements/agreement-labels.mixin";
import TableRowAvatar from "@/components/work-assignments/TableRowAvatar.vue";
import SendGigContractDialog from "../people-team/monday-onboarding/SendGigContractDialog.vue";
import ContractSignedOffsiteDialog from "../people-team/monday-onboarding/ContractSignedOffsiteDialog.vue";
import SendGigExtensionDialog from "../people-team/monday-onboarding/SendGigExtension/SendGigExtensionDialog.vue";
import UpdatableTeam from "./UpdatableTeam.vue";
import UpdatableHm from "./UpdatableHm.vue";

export default {
  mixins: [InvoiceLabelsMixin, AgreementLabelsMixin],
  components: {
    TableRowAvatar,
    SendGigContractDialog,
    ContractSignedOffsiteDialog,
    SendGigExtensionDialog,
    UpdatableTeam,
    UpdatableHm,
  },
  props: {
    value: Object,
    suggestionFromMondayCom: Object,
  },
  data() {
    return {
      form: null,
      sendDialog: false,
      signedOffsiteDialog: false,
      extensionDialog: false,
      mondayComExtensionId: null,
      mondayComExtension: null,
      mondayComExtensionSuggestion: null,
      cancelDialog: false,
      unCancelDialog: false,
    };
  },
  mounted() {
    if (this.$route.query.extension_id) {
      this.mondayComExtensionId = this.$route.query.extension_id;
      this.fetchInit();
    } else {
      this.form = { ...this.value };
    }
  },
  methods: {
    async fetchInit() {
      const geCheck = await this.checkForGigExtension();
      if (geCheck == "not_found") await this.fetchMondayExtension();
      this.form = { ...this.value };
    },
    async checkForGigExtension() {
      try {
        const res = await this.$api.GigExtension.check({
          monday_com_item_id: this.mondayComExtensionId,
        });
        if (res.status == 200) {
          return "found";
        }
      } catch (e) {
        if (e.response.status == 404) {
          return "not_found";
        } else {
          this.$alert("Something went wrong", "error");
          return "error";
        }
      }
    },
    async fetchMondayExtension() {
      try {
        const { data } = await this.$api.GigExtension.getMondayComItem({
          id: this.mondayComExtensionId,
          hire_request_id: this.value.id,
        });
        this.mondayComExtension = data.item;
        if (data.suggestion) {
          this.mondayComExtensionSuggestion = data.suggestion;
          this.$nextTick(() => {
            this.$nextTick(() => {
              this.$nextTick(() => (this.extensionDialog = true));
            });
          });
        }
      } catch (e) {
        if (e.response.status == 404) {
          this.$alert(
            "Monday.com Extention #" + this.mondayComItemId + " not found.",
            "error"
          );
        } else if (e.response.status == 422) {
          this.$alert(e.response.data.msg, "error");
        } else {
          this.$alert("Something went wrong", "error");
        }
      }
    },
    async cancel(value) {
      try {
        const res = await this.$api.HireRequest.updateCanceled({
          id: this.form.id,
          value,
        });
        if (res.status == 200) {
          this.cancelDialog = false;
          this.unCancelDialog = false;
          this.$emit("refresh");
        }
      } catch (e) {
        this.cancelDialog = false;
        this.unCancelDialog = false;
        this.$alert("Something went wrong", "error");
      }
    },
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.form = { ...this.value };
      },
    },
  },
  computed: {
    agreementNeededForAll() {
      const waCount = this.form.work_assignments.length;
      const noneCount = this.form.work_assignments.filter(
        (wa) => wa.agreement_status == "NONE"
      ).length;
      return waCount > 0 && waCount == noneCount;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/hire-requests/hire-request.scss";

.hr {
  &__gigs-header {
    display: flex;
    flex-wrap: wrap;
    margin-top: -12px;
  }
  &__contract-signed-offsite {
    margin-top: 12px;
    margin-bottom: 12px;
    flex-grow: 1;
    text-align: right;
    color: #61b90c;
    font-weight: bold;
  }

  &__actions {
    display: flex;
    justify-content: right;
    > * {
      margin-left: 3em;
    }
    .v-btn {
      color: white !important;
    }
  }

  &__canceled-msg {
    font-weight: bold;
    color: #e12d1b;
    text-align: right;
  }

  ::v-deep {
    .v-input,
    .v-input:not(.v-input--has-state) {
      fieldset {
        border-color: #262729;
        border-width: 2px;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .hr {
    &__gigs-header {
      margin-bottom: 1em;
      font-size: 20px;
    }
    &__work-assignment-list {
      margin-left: 0;
    }
  }
}
</style>
