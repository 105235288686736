<template>
  <v-dialog v-model="dialog" width="800" @click:outside="closeDialog">
    <v-card light class="retro-dialog">
      <v-btn class="close" icon @click="closeDialog">
        <v-icon size="30">$close</v-icon>
      </v-btn>
      <v-card-text>
        <div class="page-title">Retrospective Survey</div>
        <div class="retro-form">
          <div v-if="submissionForm && form">
            <div class="retro-topmatter">
              {{ submissionForm.description }}
            </div>
            <DynamicForm
              :key="form.timestamp.toDateString()"
              :submit="submit"
              :fields="submissionForm.submission_form"
              v-model="form"
              show-label
              :customComponents="submissionForm.custom_components"
            />
          </div>
          <Spinner v-else />
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import DynamicForm from "@/components/DynamicFields/DynamicForm";

export default {
  components: { DynamicForm },
  props: {
    dialog: Boolean,
    hireRequest: Object,
  },
  data() {
    return {
      form: null,
      submissionForm: null,
    };
  },
  created() {
    this.fetchForm();
  },
  methods: {
    initForm() {
      let data = {};
      data[
        this.submissionForm.submission_form.find(
          (field) => field.short_name == "hire_request"
        ).uuid
      ] = this.hireRequest.monday_com_item_id;
      data[
        this.submissionForm.submission_form.find(
          (field) => field.short_name == "gigs"
        ).uuid
      ] = this.hireRequest.work_assignments
        .map((wa) => wa.project_code)
        .join(", ");
      this.form = {
        widget_submission_form_id: this.submissionForm.id,
        data: data,
        timestamp: new Date(),
      };
    },
    async fetchForm() {
      const { data } = await this.$api.SubmissionForm.getForm({
        slug: "retrospective_survey",
      });
      this.submissionForm = data;

      this.initForm();
    },
    async submit() {
      try {
        await this.$api.Submission.create(this.form);
        this.initForm();
        this.$emit("submit", this.submissionForm.success_message);
      } catch (e) {
        this.$alert("Something went wrong", "error");
      }
    },
    closeDialog() {
      this.$emit("close");
      this.initForm();
    },
  },
};
</script>

<style lang="scss" scoped>
.retro {
  &-dialog {
    padding: 40px;
    line-height: 1.2;
    &::v-deep {
      .dynamic-select .dynamic-select__control {
        max-width: 250px;
        margin-top: 0.5em;
      }
      .dynamic-textarea .dynamic-textarea__input {
        margin-top: 0.5em;
      }
    }
  }
  &-topmatter {
    margin-bottom: 2em;
  }
}
</style>
