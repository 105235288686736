import Vue from "vue";
import { axios } from "./api.config";
// import { crud } from "@/lib";
import objectToFormData from "../lib/object-to-formdata";

Vue.prototype.$http = axios;

const Datasources = {
  get: (params) => axios.get("/datasources", { params, jwt: true }),
};

const Search = {
  search: (params) => axios.get("/search", { params, jwt: true }),
};

const Download = {
  download: (params) => axios.get("/download", { params, jwt: true }),
};

const User = {
  current: () => axios.get("/users/current", { jwt: true }),
  availability: (params) =>
    axios.post("/users/availability", params, { jwt: true }),
  profile: (params) => axios.get(`/users/${params.id}/profile`, { jwt: true }),
  update: (params, additional) =>
    axios.put("/users", objectToFormData(params), {
      jwt: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const loaded = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (additional?.onUploadProgress) {
          return additional.onUploadProgress(loaded);
        }
      },
    }),
  unlock: (params) => axios.post("/users/unlock", params, { jwt: true }),
  lite: (params) => axios.get("/users/lite", { params, jwt: true }),
  onboard: (params) => axios.post("/users/onboard", params, { jwt: true }),
};

const Creator = {
  sections: (params) => axios.get("/creators/sections", { params, jwt: true }),
  get: (params) => axios.get("/creators", { params, jwt: true }),
  showForReview: (params) =>
    axios.get(`/creators/${params.id}/show_for_review`, { jwt: true }),
  showForPayments: (params) =>
    axios.get(`/creators/${params.id}/show_for_payments`, { jwt: true }),
  profileReviews: (params) =>
    axios.get(`/creators/${params.id}/profile_reviews`, { jwt: true }),
  submitReview: (params) =>
    axios.post(`/creators/${params.id}/submit_review`, params, { jwt: true }),
  assignReviewer: (params) =>
    axios.post(`/creators/${params.id}/assign_reviewer`, params, { jwt: true }),
  removeReviewRequest: (params) =>
    axios.post(`/creators/${params.id}/remove_review_request`, params, {
      jwt: true,
    }),
  onboardingDetails: (params) =>
    axios.post("/creators/onboarding_details", objectToFormData(params), {
      jwt: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
};

const SubmissionForm = {
  get: (params) => axios.get("/submission_forms", { params, jwt: true }),
  getForm: (params) =>
    axios.get("/submission_forms/get_form", { params, jwt: true }),
};

const Submission = {
  get: (params = {}) =>
    axios.get(`/submissions${params.id ? "/" + params.id : ""}`, {
      params,
      jwt: true,
    }),
  forReview: () => axios.get("/submissions/for_review", { jwt: true }),
  saveRating: (params) =>
    axios.put(`/submissions/${params.id}/save_rating`, params, { jwt: true }),
  saveFinalist: (params) =>
    axios.put(`/submissions/${params.id}/save_finalist`, params, { jwt: true }),
  saveWinner: (params) =>
    axios.put(`/submissions/${params.id}/save_winner`, params, { jwt: true }),
  destroy: (params) =>
    axios.delete(`/submissions/${params.id}`, { params, jwt: true }),
  create: (params) =>
    axios.post("/submissions", objectToFormData(params), {
      jwt: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent, additional) => {
        const loaded = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (additional?.onUploadProgress) {
          return additional.onUploadProgress(loaded);
        }
      },
    }),
};

const Invoice = {
  get: (params) => axios.get(`invoices/${params.id}`, { params, jwt: true }),
  my: (params) => axios.get("invoices/my", { params, jwt: true }),
  earned: (params) => axios.get("invoices/earned", { params, jwt: true }),
  payments: (params) => axios.get("invoices/payments", { params, jwt: true }),
  projects: (params) => axios.get("invoices/projects", { params, jwt: true }),
  byProject: (params) =>
    axios.get("invoices/by_project", { params, jwt: true }),
  reports: (params) => axios.get("invoices/reports", { params, jwt: true }),
  download: (params) =>
    axios.post(
      `invoices/${params.id}/download${params.concur ? "_concur" : ""}`,
      objectToFormData(params),
      {
        jwt: true,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    ),
  create: (params) =>
    axios.post(`/invoices/create`, objectToFormData(params), {
      jwt: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  markAsPaid: (params) =>
    axios.put(`/invoices/mark_as_paid`, objectToFormData(params), {
      jwt: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  markAsUnpaid: (params) =>
    axios.put(`/invoices/mark_as_unpaid`, objectToFormData(params), {
      jwt: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  markAsCanceled: (params) =>
    axios.put(`/invoices/mark_as_canceled`, objectToFormData(params), {
      jwt: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  batch: (params) =>
    axios.post(`/invoices/batch`, objectToFormData(params), {
      jwt: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
};

const InvoiceEvent = {
  list: (params) => axios.get("/invoice_events/list", { params, jwt: true }),
};

const HistoricalInvoice = {
  reports: (params) =>
    axios.get("historical_invoices/reports", { params, jwt: true }),
};

const WorkAssignment = {
  get: (params) => axios.get("/work_assignments", { params, jwt: true }),
  my: (params) => axios.get("work_assignments/my", { params, jwt: true }),
  myLite: (params) =>
    axios.get("work_assignments/my_lite", { params, jwt: true }),
  hmLite: (params) =>
    axios.get("work_assignments/hm_lite", { params, jwt: true }),
  fiLite: (params) =>
    axios.get("work_assignments/fi_lite", { params, jwt: true }),
  myActiveCreators: (params) =>
    axios.get("work_assignments/my_active_creators", { params, jwt: true }),
  hmGigs: (params) =>
    axios.get("work_assignments/hm_gigs", { params, jwt: true }),
  creator: (params) =>
    axios.get("/work_assignments/creator", { params, jwt: true }),
  cancel: (params) =>
    axios.put(`/work_assignments/cancel`, params, { jwt: true }),
  update: (params) =>
    axios.put(`/work_assignments/update`, objectToFormData(params), {
      jwt: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
};

const QbClass = {
  lite: () => axios.get("qb_classes/lite", { jwt: true }),
};

const Support = {
  getForm: (params) => axios.get("/support/get_form", { params, jwt: true }),
  getForms: (params) => axios.get("/support/get_forms", { params, jwt: true }),
  getRequestReferralsForm: (params) =>
    axios.get("/support/get_request_referrals_form", { params, jwt: true }),
  create: (params) =>
    axios.post("/support/create", objectToFormData(params), {
      jwt: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent, additional) => {
        const loaded = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (additional?.onUploadProgress) {
          return additional.onUploadProgress(loaded);
        }
      },
    }),
};

const InternalProject = {
  lite: (params) => axios.get("internal_projects/lite", { params, jwt: true }),
  members: (params) =>
    axios.get(`internal_projects/${params.id}/members`, { params, jwt: true }),
  financeList: (params) =>
    axios.get(`internal_projects/finance_list`, { params, jwt: true }),
  create: (params) =>
    axios.post("/internal_projects/create", objectToFormData(params), {
      jwt: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent, additional) => {
        const loaded = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (additional?.onUploadProgress) {
          return additional.onUploadProgress(loaded);
        }
      },
    }),
};

const QbProject = {
  lite: () => axios.get("qb_projects/lite", { jwt: true }),
  search: (params) => axios.get("qb_projects/search", { params, jwt: true }),
  create: (params) =>
    axios.post(`/qb_projects/create`, objectToFormData(params), {
      jwt: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
};

const Project = {
  list: (params) => axios.get("projects/list", { params, jwt: true }),
  current: () => axios.get("/projects/current", { jwt: true }),
};

const Page = {
  get: (params) => axios.get("/pages", { params, jwt: true }),
};

const School = {
  search: (params) => axios.get("/schools/search", { params, jwt: true }),
};

const Auth = {
  login: (params) => axios.post("/auth/login", params),
  register: (params) => axios.post("/auth/register", params),
  sendConfirmationEmail: (params = {}) =>
    axios.post("/auth/send_confirmation_email", params, { jwt: true }),
  claimAccountData: (params) => axios.post("/auth/claim_account_data", params),
  resendClaimEmail: (params = {}) =>
    axios.post("/auth/resend_claim_email", params),
  refreshToken: (params = {}) =>
    axios.post("/auth/refresh_token", params, { jwt: true }),
};

const Geo = {
  search: (params) => axios.get("geo/search", { params, jwt: true }),
};

const Referral = {
  get: (params) => axios.get("referrals", { params, jwt: true }),
  create: (params) => axios.post("referrals", params, { jwt: true }),
  invite: (params) => axios.post("referrals/invite", params, { jwt: true }),
};

const Opportunity = {
  create: (params) =>
    axios.post("/opportunities", objectToFormData(params), {
      jwt: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent, additional) => {
        const loaded = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (additional?.onUploadProgress) {
          return additional.onUploadProgress(loaded);
        }
      },
    }),
  templates: (params) =>
    axios.get("/opportunities/templates", { params, jwt: true }),
};

const RegistrationForm = {
  submit: (params) =>
    axios.post("/registration_forms/submit", objectToFormData(params), {
      jwt: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent, additional) => {
        const loaded = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (additional?.onUploadProgress) {
          return additional.onUploadProgress(loaded);
        }
      },
    }),
  root: () => axios.get("registration_forms/root", {}),
};

const ProfileNotification = {
  oppListVisit: (params) =>
    axios.post("profile_notifications/opp_list_visit", params, { jwt: true }),
  get: (params) => axios.get("/profile_notifications", { params, jwt: true }),
  markAsRead: (params) =>
    axios.post("profile_notifications/mark_as_read", params, { jwt: true }),
};

const GDriveImageLibrary = {
  list: () => axios.get("gdrive_image_library/list", { jwt: true }),
  getImage: (params) =>
    axios.get("gdrive_image_library/get_image", {
      params,
      jwt: true,
      responseType: "blob",
    }),
};

const Agreement = {
  list: (params) => axios.get("agreements/list", { params, jwt: true }),
  my: (params) => axios.get("agreements/my", { params, jwt: true }),
  create: (params) =>
    axios.post(`/agreements/create`, objectToFormData(params), {
      jwt: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  uploadSigned: (params) =>
    axios.post(`/agreements/upload_signed`, objectToFormData(params), {
      jwt: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  replaceUpload: (params) =>
    axios.post(`/agreements/replace_upload`, objectToFormData(params), {
      jwt: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  cancel: (params) =>
    axios.put(`/agreements/${params.id}/cancel`, params, { jwt: true }),
  sendReminder: (params) =>
    axios.put(`/agreements/${params.id}/send_reminder`, params, { jwt: true }),
  documentUrl: (params) =>
    axios.get(`/agreements/${params.id}/document_url`, { params, jwt: true }),
  signingUrl: (params) =>
    axios.get(`/agreements/signing_url`, { params, jwt: true }),
  requestMy: (params) =>
    axios.get(`/agreements/request_my`, { params, jwt: true }),
};

const HireRequest = {
  list: (params) => axios.get("/hire_requests/list", { params, jwt: true }),
  lite: (params) => axios.get("/hire_requests/lite", { params, jwt: true }),
  get: (params) => axios.get(`/hire_requests`, { params, jwt: true }),
  getMondayComItem: (params) =>
    axios.get(`/hire_requests/get_monday_com_item/${params.id}`, {
      params,
      jwt: true,
    }),
  create: (params) =>
    axios.post(`/hire_requests/create`, objectToFormData(params), {
      jwt: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  update: (params) =>
    axios.put(`/hire_requests/update`, objectToFormData(params), {
      jwt: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  creator: (params) =>
    axios.get("/hire_requests/creator", { params, jwt: true }),
  my: (params) => axios.get("hire_requests/my", { params, jwt: true }),
  getMy: (params) => axios.get(`hire_requests/get_my`, { params, jwt: true }),
  markAsSignedOffsite: (params) =>
    axios.put(`/hire_requests/${params.id}/mark_as_signed_offsite`, params, {
      jwt: true,
    }),
  updateCanceled: (params) =>
    axios.put(`/hire_requests/${params.id}/update_canceled`, params, {
      jwt: true,
    }),
};

const GigExtension = {
  getMondayComItem: (params) =>
    axios.get(`/gig_extensions/get_monday_com_item/${params.id}`, {
      params,
      jwt: true,
    }),
  create: (params) =>
    axios.post(`/gig_extensions/create`, objectToFormData(params), {
      jwt: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  get: (params) =>
    axios.get(`gig_extensions/${params.id}`, { params, jwt: true }),
  check: (params) =>
    axios.get(`gig_extensions/check/${params.monday_com_item_id}`, {
      params,
      jwt: true,
    }),
  lite: (params) => axios.get("/gig_extensions/lite", { params, jwt: true }),
  updateCanceled: (params) =>
    axios.put(`/gig_extensions/${params.id}/update_canceled`, params, {
      jwt: true,
    }),
};

Vue.prototype.$api = {
  Geo,
  User,
  Datasources,
  Search,
  Submission,
  SubmissionForm,
  Creator,
  Support,
  Project,
  Auth,
  School,
  RegistrationForm,
  Page,
  Referral,
  ProfileNotification,
  Opportunity,
  GDriveImageLibrary,
  Invoice,
  QbClass,
  WorkAssignment,
  InternalProject,
  Agreement,
  HireRequest,
  QbProject,
  GigExtension,
  Download,
  InvoiceEvent,
  HistoricalInvoice,
};
