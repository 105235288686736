<template>
  <div class="my-invoices">
    <div>
      <div class="submit-payment">
        <div class="page-title">Submit new invoice</div>
        <div class="submit-payment__actions">
          <v-btn
            color="#4282FF"
            @click="showNewInvoice = !showNewInvoice"
            elevation="0"
          >
            Submit New Invoice
          </v-btn>
          <SelectInvoiceMenu
            class="my-invoices__create-from-previous-toggle"
            @close="createFromPreviousInvoiceDialog = false"
            @toggle="
              createFromPreviousInvoiceDialog = !createFromPreviousInvoiceDialog
            "
            :menu="createFromPreviousInvoiceDialog"
            :themeDisabledPage="themeDisabledPage"
            @selectInvoice="selectInvoice($event)"
          />
        </div>
      </div>
      <SubmitInvoiceDialog
        :dialog="showNewInvoice"
        @submit="submitInvoice()"
        @close="showNewInvoice = false"
        :invoiceTemplate="invoiceTemplate"
        :key="`${invoiceTemplate ? invoiceTemplate.id : 'new'}-invoice-dialog`"
      />
      <div :key="`reload-key-${reloadKey}`">
        <MyPaymentsSection
          title="Pending Payments"
          :key="`pending-section-${reloadKey}`"
        />
        <MyPaymentsSection
          title="Completed Payments"
          completedPayments
          :key="`completed-section-${reloadKey}`"
        />
      </div>
      <v-dialog
        v-model="showPostSubmitMsg"
        @click:outside="showPostSubmitMsg = false"
        light
        max-width="500"
      >
        <v-card light class="post-submit-msg">
          <v-btn class="close" icon @click="showPostSubmitMsg = false">
            <v-icon size="30">$close</v-icon>
          </v-btn>
          <v-card-text>
            Your invoice has been successfully submitted! <br />
            Payments are processed on Fridays. Please expect to see payment in
            your account within 5-7 business days from the processing date. If
            you do not receive payment within this timeframe, please contact us
            at
            <a target="_blank" href="mailto:accounting@ideasunited.com"
              >accounting@ideasunited.com</a
            >.
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import themeDisabledPageMixin from "@/mixins/theme-disabled-page.mixin";
import SubmitInvoiceDialog from "./submit-invoice-dialog.my-payments";
import MyPaymentsSection from "./section.my-payments";
import SelectInvoiceMenu from "./select-invoice-menu.my-payments";

export default {
  name: "MyPaymentsPage",
  mixins: [themeDisabledPageMixin],
  components: {
    SubmitInvoiceDialog,
    MyPaymentsSection,
    SelectInvoiceMenu,
  },
  data() {
    return {
      showNewInvoice: false,
      createFromPreviousInvoiceDialog: false,
      invoiceTemplate: null,
      reloadKey: 1,
      showPostSubmitMsg: false,
    };
  },
  created() {
    this.fetchEarned();
    this.$cable.subscribe({
      channel: "InvoiceCreatorChannel",
      token: this.authToken,
      env: this.isProduction() ? "production" : "staging",
    });
  },
  methods: {
    async fetchEarned() {
      const { data } = await this.$api.Invoice.earned();
      this.$emit("earned", data);
    },
    refresh() {
      this.reloadKey++;
    },
    submitInvoice() {
      this.showNewInvoice = false;
      this.showPostSubmitMsg = true;
      this.refresh();
    },
    selectInvoice(invoice) {
      this.showNewInvoice = false;
      this.$nextTick(() => {
        this.invoiceTemplate = invoice;
        this.showNewInvoice = true;
        this.createFromPreviousInvoiceDialog = false;
      });
    },
    async handleInvoiceBroadcast(type, payload) {
      if (
        !payload.transaction_id ||
        payload.transaction_id != this.$store.getters["invoice/transactionId"]
      ) {
        this.refresh();
        switch (type) {
          case "hm_pay_directly":
            this.$alert(
              "Your manager just submitted a new payment for you! 🎉"
            );
            break;
          case "fi_pay_directly":
            this.$alert(
              "Our finance department just submitted a new payment for you! 🎉"
            );
            break;
          default:
            this.$alert("Invoices updated.");
        }
      }
      this.$emit("checkUrlParamInvoice", { type, payload });
    },
  },
  channels: {
    InvoiceCreatorChannel: {
      connected() {},
      received({ type, payload }) {
        this.handleInvoiceBroadcast(type, payload);
      },
    },
  },
  beforeDestroy() {
    this.$cable.unsubscribe({
      channel: "InvoiceCreatorChannel",
    });
  },
};
</script>

<style scoped lang="scss">
.my-invoices {
  margin-top: 72px;

  &__load-more {
    display: flex;
    justify-content: center;
    padding-top: 45px;
  }
}
.submit-payment {
  background: white;
  border: 2px solid #262729;
  border-radius: 10px;
  padding: 17px 23px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .page-title {
    margin: 0;
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    > *:not(:last-child) {
      margin-right: 26px;
    }
    .v-btn {
      color: white !important;
    }
  }
}
.my-payment {
  padding: 40px;
  background: white;
  border: 2px solid #262729;
  margin-top: -2px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 960px) {
    padding: 25px 12px 16px;
  }

  &__date {
    font-style: italic;
    font-size: 12px;
    margin-top: 15px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.5);
  }

  &__links {
    font-weight: 600;
    font-size: 9px;
    text-transform: uppercase;

    a {
      text-decoration: none;
      margin-right: 20px;
    }
  }

  a.my-payment__title {
    font-weight: bold;
    font-size: 14px;
    color: var(--v-dark-base);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &__status {
    flex-basis: 260px;
    @media screen and (max-width: 960px) {
      flex-basis: 100%;
    }
  }

  &__create-from-previous-toggle {
    margin: 30px;
    .actions-menu-wrapper {
      display: none;
    }
  }
}

.table-loading {
  pointer-events: none;
  opacity: 0.5;
}

.post-submit-msg {
  padding: 35px;
}
.post-submit-msg .v-card__text {
  font-weight: bold;
  text-align: center;
  padding-bottom: 0;
}
</style>
